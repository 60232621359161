<template>
	<div>
		<div class="conters">
			<div class="left_tabbox">
				<div class="top_info">
					<div class="user_head">
						<img :src="userInfo.avatar ? URL + userInfo.avatar : ''" class="head">
					</div>
					<div>
						<div class="user_name">{{ userInfo.nickname ? userInfo.nickname : "" }}</div>
						<div class="logout" @click="gologin">退出登录</div>
					</div>
				</div>
				<div class="tabbox">
					<div :class="item.id == ids ? 'true_style' : 'false_style'" v-for="(item, index) in tablist"
						:key="index" @click="tabchange(item)">
						<div class="lines" v-if="item.id == ids"></div>
						{{ item.name }}
					</div>
				</div>
			</div>
			<div class="right_box">
				<div class="search_box">
					<div class="flex">
						<el-input v-model="articletags" placeholder="请输入关键词"
							style="height: 34px;width: 200px;margin-left: 20px;"></el-input>
						<div class="btns" @click="queryList">查询</div>
					</div>
					<div class="allNum">总数：{{ total ? total : '0' }}</div>
				</div>
				<div class="values">
					<div class="card" v-for="(item, index) in list" :key="index" >
						<div class="code_imgs">
							<img :src="URL + item.images[0]" alt=""  class="images">
						</div>
						<div class="info_box">
							<div class="flex1">
								<div class="type_label" >{{ item.categorys_name }}</div>
								<div class="program_name">{{ item.name }}</div>
							</div>
							<div class="rows">项目介绍：{{ item.introduce }}</div>
							<div class="rows" style="color: #333;">项目演示：</div>
							<div class="rows" v-html="item.show_adds"></div>
						</div>
						<div class="status" v-if="item.is_top ==1">已上线</div>
						<div class="status" v-if="item.is_top ==0">未上线</div>
					</div>
				</div>
				<div class="pages" v-if="total > 0">
					<el-pagination background layout="prev, pager, next" :page-size="4" hide-on-single-page :total="total" @current-change="currentChange"
						:current-page="page"></el-pagination>
				</div>
				<div class="noList" v-if="total == 0">
                   <img src="../assets/no_value.png" class="iocns">
				   <span class="tips">暂无数据！</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			tablist: [],
			ids: '0',
			userInfo: {},
			URL: this.$baseUrl,
			page: 1,
			total: 0,
			list: [],


			options: [{
				value: '1',
				label: '小程序'
			}, {
				value: '2',
				label: '公众号'
			}, {
				value: '3',
				label: 'H5'
			}, {
				value: '4',
				label: 'APP'
			}, {
				value: '5',
				label: 'PC'
			}],
			typeName: '',
			articletags: '',
			type: '',
		}
	},
	mounted() {
		let token = localStorage.getItem("token")
		if (!token) {
			this.$message.error('请登录');
			setTimeout(() => {
				this.$router.push('/login')
			}, 500)
		} else {
			let obj = localStorage.getItem('user_info')
			this.userInfo = JSON.parse(obj)
			this.inittabList()
			this.initList()
		}
	},
	methods: {
		selectChange(e) {
			this.type = e
		},
		queryList() {
			this.page = 1
			this.list = []
			this.total = []
			this.initList()
		},
		clearSelect() {
			this.type = ''
			this.typeName = ''
		},
		currentChange(e) {
			this.page = e
			this.list = []
			this.total = 0
			this.initList()
		},
		tabchange(e) {
			this.ids = e.id
			this.list = []
			this.page = 1
			this.articletags = ''
			this.total = 0
			this.initList()
		},
		// 获取页面数据
		initList() {
			this.request.post("/api/user/item", {
				categorys_id: this.ids,
				page: this.page,
				limit: 4,
				type: this.type,
				articletags: this.articletags
			}).then(res => {
				if (res.code == 1) {
					this.list = res.data.data
					this.total = Math.ceil(res.data.total)
				} else {
					this.$message.error(res.msg);
				}
			})
		},
		inittabList() {
			this.request.post("/api/user/categorys", {}).then(res => {
				if (res.code == 1) {
					let arr = res.data
					let obj = {
						id: '0',
						name: '案例库'
					}
					arr.unshift(obj)
					this.tablist = arr

				} else {
					this.$message.error(res.msg);
				}
			})
		},
		gologin() {
			localStorage.setItem('token', '')
			localStorage.setItem('user_info', '')
			this.$message({
				message: '退出成功',
				type: 'success'
			});
			this.$router.push('/login')
		},
		godetail(e) {
			this.$router.push('/detail?id=' + e.id)
		},
	}
}
</script>

<style scoped>
* {
	margin: 0;
	padding: 0;
}
.el-select-dropdown__item{
	padding-left: 20px !important;
}
.el-input__inner {
	width: 200px;
	height: 30px !important;

	::v-deep .el-input {
		.el-input__inner {
			height: 30px;
		}

		.el-input__prefix,
		.el-input__suffix {
			height: 30px;
		}
	}
}


/* 设置滚动条的宽度 */
::-webkit-scrollbar {
	width: 10px;
	/* 水平滚动条的高度 */
	height: 10px;
	/* 垂直滚动条的宽度 */
}

div {
	box-sizing: border-box;
}

.conters {
	width: 100vw;
	height: 100vh;
	background-color: #f4f5f7;
	overflow: hidden;
	position: relative;
}

.left_tabbox {
	width: 12%;
	height: 100%;
	background-color: #334053;
	position: absolute;
	left: 0;
	border-right: 1px solid #D8D8D890;
}

.top_info {
	width: 100%;
	height: 80px;
	display: flex;
	align-items: center;
	padding-left: 20px;
}

.user_head {
	width: 50px;
	height: 50px;
	border-radius: 30px;
	margin-right: 8px;
}

.head {
	width: 100%;
	height: 100%;
	border-radius: 30px;
}

.user_name {
	color: #FFFFFF;
	font-size: 18px;
}

.logout {
	color: #FFFFFF;
	font-size: 12px;
	margin-top: 6px;
}

.tabbox {
	width: 100%;
	height: calc(100vh - 80px);
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}

.false_style {
	display: flex;
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;
	height: 60px;
	min-height: 60px;
	align-items: center;
	color: #FFFFFF;
	font-size: 14px;
	text-align: center;
	padding-left: 30px;
}

.true_style {
	display: flex;
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;
	height: 60px;
	align-items: center;
	color: #FFFFFF;
	font-size: 14px;
	text-align: center;
	min-height: 60px;
	background-color: #4E5E74;
}

.lines {
	width: 2px;
	height: 20px;
	background-color: #FFFFFF;
	margin-right: 8px;
}

.right_box {
	width: 88%;
	height: 100%;
	position: absolute;
	right: 0;
	padding-top: 20px;
	padding-left: 30px;
	padding-right: 30px;
}

.search_box {
	width: 100%;
	height: 60px;
	background-color: #fff;
	padding-left: 20px;
	padding-right: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.flex {
	display: flex;
	align-items: center;
}

.btns {
	width: 60px;
	height: 30px;
	background-color: #337CFB;
	margin-left: 20px;
	font-size: 14px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #FFFFFF;
	margin-top: 4px;
}

.allNum {
	color: #333333;
	font-size: 16px;
}

.values {
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
}
.status{
	width: 100px;
	height: 30px;
	background-color: #337cfb;
	position: absolute;
	top: 10px;
	right: -26px;
	color: #FFFFFF;
	font-size: 14px;
	text-align: center;
	line-height: 30px;
	transform: rotate(45deg);
}

.pages {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	margin-top: 20px;
}

.card {
	width: 49%;
	height: 38vh;
	background-color: #FFFFFF;
	margin-top: 20px;
	padding: 20px;
	border-radius: 5px;
	display: flex;
	overflow: hidden;
	position: relative;
}

.code_imgs {
	width: 200px;
	height: calc(38vh - 40px);
	margin-right: 40px;
	min-width: 200px;
	overflow: hidden;
}

.images {
	width: 100%;
}

.info_box {
	width: calc(100% - 280px);
	height: 100%;
}

.flex1 {
	display: flex;
	align-items: center;
}

.type_label {
	border: 1px solid #E45656;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	color: #E45656;
	margin-right: 10px;
	border-radius: 2px;
	padding: 4px 10px;
}

.program_name {
	font-size: 20px;
	color: #337CFB;
}

.label_box {
	display: flex;
	align-items: center;
	margin-top: 20px;
	flex-wrap: nowrap;
}

.label {
	padding: 2px 6px;
	background-color: #f1f0f6;
	color: #999999;
	font-size: 14px;
	margin-right: 6px;
}

.rows {
	width: 100%;
	color: #999999;
	font-size: 16px;
	margin-top: 14px;

}
.noList{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.iocns{
	width: 400px;
	height: 400px;
}
.tips{
	color: #999999;
	font-size: 14px;
}
</style>